<template>
    <div class="flexPage payment">
        <div class="flexHead">
            <navbar :params="pageInfo" />
        </div>
        <p class="tips">{{ $t('other.translate97') }}</p>

        <van-cell-group :border="false">
            <van-cell :title="$t('other.translate98')" @click.native="$mts.goto('bindBankCard')" is-link
                :value="user.bankVerified == 1 ? user.bankInfo.cardNo : $t('other.translate102')" />
            <van-cell :title="$t('other.translate99')" @click.native="$mts.goto('bindAlipay')" is-link
                :value="user.aliVerified == 1 ? user.alipay.aliNo : $t('other.translate102')" />
            <van-cell :title="$t('other.translate100')" @click.native="$mts.goto('bindWechatPay')" is-link
                :value="user.wechatVerified == 1 ? user.wechatPay.wechat : $t('other.translate102')" />
            <van-cell :title="$t('other.translate101')" @click.native="$mts.goto('bindSwift')" is-link
                :value="user.swiftVerified == 1 ? user.swift.swiftAddress : $t('other.translate102')" />
        </van-cell-group>
    </div>
</template>
<script>
import navbar from "@/views/modules/navbar.vue";

export default {
    data() {
        return {
            pageInfo: {
                customTitle: '',
                navbarStyle: "normal",
                backname: "safe",
            },
            user: {}
        }
    },
    components: { navbar },
    mounted() {
        this.getAccount();
    },
    methods: {
        getAccount() {
            //获取个人账户信息
            this.$http.post(this.host + "/uc/approve/account/setting").then((response) => {
                var resp = response.body;
                if (resp.code == 0) {
                    this.user = resp.data;
                } else {
                    this.$toast(resp.message);
                }
            });
        },
    }
}
</script>
<style>
.payment {
    height: 100vh;
    background: #F8FAFD;
}

.payment .tips {
    padding-left: 3.8168vw;
    display: flex;
    align-items: center;
    height: 7.6336vw;
    background: #EFF0F2;
    font-size: 12px;
    font-weight: 510;
    color: #616E85;
    margin-bottom: 0;
}

.payment .van-cell-group .van-cell:not(:last-child):after {
    left: 0;
}
</style>